import React, { useState, useRef} from 'react';
import axios from 'axios';
import { Form, Container, Row, Col, Button } from 'react-bootstrap';
//import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';
import ReCAPTCHA from 'react-google-recaptcha';

import LinkMailto from '../components/LinkMailto';
import PageBanner from '../components/PageBanner';
import InputMask from 'react-input-mask';

const ContactUs = () => {
  const [state, setState] = useState({
    fullName: '',
    email: '',
    phoneNumber: '',
    subject: '',
    message: '',
  });

  const [buttonLoading, setButtonLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const fullNameRef = useRef(null);
  const emailRef = useRef(null);
  const recaptchaRef = useRef(null);
  const phoneNumberRef = useRef(null);
  const subjectRef = useRef(null);
  const messageRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [captchaValue, setCaptchaValue] = useState(null);
  

  // useEffect(() => {
  //   loadCaptchaEnginge(6);
  // }, []);

  const sendEmail = async (event) => {
    event.preventDefault();
    // Perform your custom form validation here
    const errors = {};
    if (!state.fullName) {
      errors.fullName = 'Please enter your full name.';
    }
    if (!state.email) {
      errors.email = 'Email address is required.';
    } else if (!isValidEmail(state.email)) {
      errors.email = 'Please enter a valid email address.';
    }
    if (!state.phoneNumber) {
      errors.phoneNumber = 'Phone number is required.';
    }
    if (!state.subject) {
      errors.subject = 'Subject is required.';
    }
    if (!state.message) {
      errors.message = 'Message is required.';
    }
    // Add more validation logic as needed

    // If there are validation errors, set the state and return
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);

      const firstErrorField = Object.keys(errors)[0];
      const targetRef = firstErrorField === 'fullName' ? fullNameRef : emailRef;
      if (targetRef && targetRef.current) {
        targetRef.current.focus();

        // Adjust the scroll position to account for the fixed header
        const headerHeight = 300;
        const scrollPosition = targetRef.current.offsetTop - headerHeight;

        window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
      }

      // Set the captcha error message
      setErrorMessage('Captcha validation failed');

      // Return without sending the email
      return;
    }
    // If no validation errors, proceed with the API call
    setButtonLoading(true);


    if (captchaValue) {
      setErrorMessage(''); // Clear captcha error message
      try {
        const response = await axios.post('/send', { ...state });
        setResult(response.data);
        setState({
          fullName: '',
          email: '',
          phoneNumber: '',
          message: '',
          subject: '',
        });
        setValidationErrors({});
        setCaptchaValue('');
        // Reset reCAPTCHA and form state after submission
        recaptchaRef.current.reset();
      } catch (error) {
        setResult({
          success: false,
          message: 'Oops! Email not sent. Try again later...',
        });
      }
    } else {
      setErrorMessage('Captcha validation failed');
    }
    setButtonLoading(false);
  };

  const onInputChange = (event) => {
    const { name, value } = event.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const onCaptchaChange = (value) => {
    if (!value) {
      setErrorMessage('Captcha validation failed');
    } else {
      setErrorMessage('');
    }

    setCaptchaValue(value);
   // setErrorMessage('');
  };

  const handleCaptchaExpired  = () => {   
    setErrorMessage('CAPTCHA expired, please try again');
    recaptchaRef.current.reset();
    const recaptchaWidget = recaptchaRef.current;
    if (recaptchaWidget) {
      const errorMessage = recaptchaWidget.querySelector('.rc-anchor-error-msg');
      if (errorMessage) {
        errorMessage.style.display = 'none';
      }
    }
  };

  const isValidEmail = (email) => {
    // Add your email validation logic here (e.g., regex validation)
    // Example regex for a basic email format:
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <>
      <div>
        <PageBanner title="Contact Us" />
        <Container fluid className="p-4 p-lg-5">
          <Row className="justify-content-md-center">
            <Col lg={8} xs={12}>
              <Row>
                <Col>
                  <h5 className="text-center mb-5 fw-semibold">
                    Send us a message by filling out the form below!
                  </h5>
                  {result && (
                    <p className={`${result.success ? 'success' : 'alert alert-danger text-center text-danger fw-bold p-4'}`}>
                      {result.message}
                    </p>
                  )}

                  <Form className="contactform" onSubmit={sendEmail} noValidate>
                    <Form.Floating className={`mb-4 ${validationErrors.fullName ? 'has-error' : ''}`}>
                      <Form.Control
                        ref={fullNameRef}
                        value={state.fullName}
                        onChange={onInputChange}
                        id="fullName"
                        name="fullName"
                        type="text"
                        placeholder="Your full name"
                        required
                      />
                      <label htmlFor="FullName"><span className="text-danger">*</span> Your full name</label>
                      {validationErrors.fullName && (
                        <div className="text-danger">{validationErrors.fullName}</div>
                      )}
                    </Form.Floating>

                    <Form.Floating className={`mb-4 ${validationErrors.email ? 'has-error' : ''}`}>
                      <Form.Control
                        ref={emailRef}
                        value={state.email}
                        onChange={onInputChange}
                        id="emailAddress"
                        name="email"
                        type="email"
                        placeholder="Your email address"
                        required
                      />
                      <label htmlFor="emailAddress"><span className="text-danger">*</span> Your email address</label>
                      {validationErrors.email && (
                        <div className="text-danger">{validationErrors.email}</div>
                      )}
                    </Form.Floating>

                    <Form.Floating className={`mb-4 ${validationErrors.phoneNumber ? 'has-error' : ''}`}>
                      <InputMask
                        mask="(999) 999-9999"
                        value={state.phoneNumber}
                        onChange={onInputChange}
                      >
                        {(inputProps) => (
                          <Form.Control
                            {...inputProps}
                            ref={phoneNumberRef}
                            id="phoneNumber"
                            name="phoneNumber"
                            type="text" // Change type to text to avoid mobile browsers showing a numeric keypad
                            placeholder="Your phone number"
                            required
                          />
                        )}
                      </InputMask>
                      <label htmlFor="phoneNumber"><span className="text-danger">*</span> Your phone number</label>
                      {validationErrors.phoneNumber && (
                        <div className="text-danger">{validationErrors.phoneNumber}</div>
                      )}
                    </Form.Floating>

                    <Form.Floating className={`mb-4 ${validationErrors.subject ? 'has-error' : ''}`}>
                      <Form.Control
                        ref={subjectRef}                      
                        value={state.subject}
                        onChange={onInputChange}
                        id="subject"
                        name="subject"
                        type="text"
                        placeholder="subject"
                        required
                        />
                      <label htmlFor="subject"><span className="text-danger">*</span> Subject</label>
                      {validationErrors.subject && (
                        <div className="text-danger">{validationErrors.subject}</div>
                      )}
                    </Form.Floating>                    

                    <Form.Floating className={`mb-4 ${validationErrors.message ? 'has-error' : ''}`}>
                      <Form.Control
                        ref={messageRef}
                        value={state.message}
                        onChange={onInputChange}
                        id="message"
                        name="message"
                        as="textarea"
                        rows={3}
                        placeholder="Type your message here..."
                        required
                      />
                      <label htmlFor="message"><span className="text-danger">*</span> Type your message here...</label>
                      {validationErrors.message && (
                        <div className="text-danger">{validationErrors.message}</div>
                      )}
                    </Form.Floating>
                    <div className='text-center'>
                      <div className="form-group bg-light border rounded-2 p-3">
                        <Row className="captchabox">
                          {/* <Col lg={3} md={5} sm={6} xs={12} className='mb-lg-0 mb-2 text-start'>
                            <LoadCanvasTemplate />
                          </Col>
                          <Col lg={5} md={5} sm={6} xs={12}>
                            <Form.Floating>
                              <Form.Control
                                placeholder="Enter Captcha Value"
                                id="user_captcha_input"
                                name="user_captcha_input"
                                type="text" />
                              <label htmlFor="user_captcha_input"><span className="text-danger">*</span> Enter rr Captcha Value</label>
                            </Form.Floating>
                            <div className='text-danger text-start'>{errorMessage}</div>
                          </Col> */}
                            <Col lg={6} md={12} sm={12} xs={12} className="mb-lg-0 mb-2 text-start">                             
                                  <ReCAPTCHA
                                    sitekey="6Lfvlm8qAAAAAB-c8WFd_GKYxskbGGaZnpTFUu7M" // Replace with your site key
                                    onChange={onCaptchaChange}
                                    ref={recaptchaRef}
                                    onExpired={handleCaptchaExpired}                                  
                                  />
                                <div className="text-danger text-start">{errorMessage}</div>                             
                            </Col>
                           
                        </Row>
                      </div>
                      <div className="col mt-3">
                        <Button type="submit" className='button large submit' disabled={buttonLoading && true}>{buttonLoading ? 'Sending..' : 'SUBMIT'}</Button>
                      </div>
                    </div>
                  </Form>
                </Col>
              </Row>
              <Row className='mt-5'>
                <Col lg={6} sm={12}>
                  <h5 className='fw-semibold text-uppercase'>New York</h5>
                  <p>
                    33 Park PL<br />
                    S110, New York, NY 10007<br />
                    Phone: +1 347 448 4997<br />
                    <LinkMailto label="contactus@opendr.com" mailto="mailto:contactus@opendr.com" />
                  </p>
                </Col>
                <Col lg={6} sm={12}>
                  <h5 className='fw-semibold text-uppercase'>Mumbai</h5>
                  <p>
                    Raheja Platinum Building<br />
                    1st Floor, Office number - 01A127 & 01A126<br />
                    Sag Baug Rd, Off Andheri-Kurla Rd, Marol<br />
                    Andheri East, Mumbai - 400059<br />
                    {/*Phone: <Nav.Link href="tel:+91 02225973290" className='me-3 d-inline'>+91 02225973290</Nav.Link>*/}
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
export default ContactUs
